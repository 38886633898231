import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import { canView } from '../../modules/utils';
import "./navbar.css"
import i18next from "i18next";
import {setLanguage} from "../../actions";

const mapStateToProps = (state) => {
    return { 
        user: state.user,
        language: state.language
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: (lang) => dispatch(setLanguage(lang))
    }
}

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    logout() {
        window.firebase.auth().signOut();
    }
    handleLanguage = async (l) => {
        this.props.setLanguage(l)
    }
    render() {
        const nome = this.props.user ? this.props.user.nome : '';
        return(
            <nav className={"main-header navbar navbar-expand navbar-white navbar-light" }>
                <div id="brand">
                    <img src="../../img/logo_orizz.png" />
                </div>
                <div className='navElements'>
                    <ul className="navbar-nav">
                        {canView('Prenotazione') ? <li className="nav-item">
                            <Link to="/prenotazione"><img src="../../img/ICON_BOOK.png"/><p>{i18next.t("prenota")}</p>
                            </Link>
                        </li> : ''}
                        <li className="nav-item">
                            <Link to="/storico"><img src="../../img/ICON_HISTORY.png"/><p>{i18next.t("storico")}</p>
                            </Link>
                        </li>
                        {canView('Assenze') ? <li className="nav-item">
                            <Link to="/assenze">
                                <div className='faIco'>
                                    <span className="fa fa-calendar-times"></span>
                                </div>
                                <p>{i18next.t("assenze")}</p>
                            </Link>
                        </li> : ''}
                        {canView('Listino') ? <li className="nav-item">
                            <Link to="/listino"><img src="../../img/ICON_PRICE.png"/><p>{i18next.t("prezzi")}</p></Link>
                        </li> : ''}
                        {canView('Utenti') ? <li className="nav-item utenti">
                            <Link to="/utenti"><img src="../../img/group.png"/><p>{i18next.t("utenti")}</p></Link>
                        </li> : ''}
                    </ul>
                    <div className='langChooser'>
                        <button
                            className={'lang ' + (this.props.language === 'it' ? 'selected' : '')}
                            onClick={this.handleLanguage.bind(this,'it')}
                            title={i18next.t("italiano")}
                        >
                            <img src="/img/it.svg" />
                        </button>
                        <button 
                            className={'lang ' + (this.props.language === 'en' ? 'selected' : '')} 
                            onClick={this.handleLanguage.bind(this,'en')}
                            title={i18next.t("inglese")}
                        >
                            <img src="/img/gb.svg" />
                        </button>
                    </div>
                </div>
                <button id="logout" onClick={this.logout}>
                    {nome} <img src="../../img/ICON_EXIT.png" />
                </button>
            </nav>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));