import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from 'i18next-browser-languagedetector';

//Import all translation files
import English from "./translations/English.json";
import Italian from "./translations/Italian.json";

const resources = {
    en: {
        translation: English,
    },
    it: {
        translation: Italian,
    },
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'it',
        debug: true,
    });

export default i18next;