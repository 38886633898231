import history from "./history";
import store from "./store";

const permissions = {
    'Utenti': ['admin'],
    'Prenotazione': ['admin', 'b2b'],
    'Listino': ['admin', 'b2b'],
    'Assenze': ['admin', 'cdi'],
}

function getState() {
    return store.getState()
}

export function goTo(path) {
    history.push(path);
};

export function goBack() {
    history.goBack();
};

export function canView(functionality) {
    let can = false;
    const state = getState();
    let role = 'user';
    if(state.user && state.user.role) {
        for(let r in state.user.role) {
            const cRole = state.user.role[r];
            if(cRole===true) {
                role = r;
            }
        }
    }
    if(permissions.hasOwnProperty(functionality) && permissions[functionality].indexOf(role)!=-1) {
        can = true;
    }
    return can;
};

export function getContractsByCompany(company) {
    return new Promise((resolve) => {
        const contracts = [];
        if(company && company != "") {
            window.firebase.firestore().collection("contratti")
                .where("company", "==", company)
                .get()
                .then((snap) => {
                    if(!snap.empty) {
                        for(let doc of snap.docs) {
                            const contratto = doc.data();
                            contracts.push(contratto);
                        }
                    }
                    resolve(contracts);
                })
                .catch((err) => {
                    console.error(err);
                    resolve(contracts);
                })
        } else {
            resolve(contracts);
        }
    });
};