export function setBookingData(payload) {
    return { type: 'BOOKING_DATA', payload }
};
export function clearBooking(payload) {
    return { type: 'CLEAR_BOOKING', payload }
};
export function saveUserData(payload) {
    return { type: 'SAVE_USER_DATA', payload }
};
export function setLanguage(payload) {
    return { type: 'CHANGE_LANGUAGE', payload}
}