import i18next from "i18next";
import i18n from "../i18n";

const initialState = {
    'booking_data': {},
    'language': localStorage.getItem("i18nextLng")
    //'language': i18next.language || localStorage.getItem("i18nextLng")
};

function rootReducer(state = initialState, action) {
    let new_state = state;
    if (action.type === 'BOOKING_DATA') {
        new_state = Object.assign({}, state, {
            'booking_data': action.payload
        });
    } else if (action.type === 'CLEAR_BOOKING') {
        new_state = Object.assign({}, state, {
            'booking_data': {}
        });
    } else if (action.type === 'SAVE_USER_DATA') {
        new_state = Object.assign({}, state, {
            'user': action.payload
        });
    } else if (action.type === 'CHANGE_LANGUAGE') {
        new_state = Object.assign({}, state, {
            'language': action.payload
        });
        i18n.changeLanguage(action.payload)
    }
    window.sessionStorage.setItem("state", JSON.stringify(new_state))
    return new_state;
}

export default rootReducer;