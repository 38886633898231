import React, { Suspense } from 'react';
import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { saveUserData } from "./actions/index";
import Navbar from "./components/navbar";
import './App.css';
import {signInWithCustomToken} from "./modules/utils";

const Login = React.lazy(() => import('./components/login'));
const Forgot = React.lazy(() => import('./components/login/forgot'));
const Dashboard = React.lazy(() => import('./components/dashboard'));
const Prenotazione = React.lazy(() => import('./components/prenotazione'));
const Riepilogo = React.lazy(() => import('./components/prenotazione/riepilogo'));
const Thankyou = React.lazy(() => import('./components/prenotazione/thankyou'));
const Storico = React.lazy(() => import('./components/storico'));
const Listino = React.lazy(() => import('./components/listino'));
const Registrazione = React.lazy(() => import('./components/login/register'));
const Utenti = React.lazy(() => import('./components/utenti'));
const Assenze = React.lazy(() => import('./components/assenze'));
const Impersona = React.lazy(() => import('./components/impersona'))
const mapDispatchToProps = dispatch => {
  return {
    saveUserData: (sud) => dispatch(saveUserData(sud))
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //this.gestoreClick = this.gestoreClick.bind(this);
  }
  componentDidMount() {
    let self = this;
    window.firebase.auth().onAuthStateChanged(function(userSnap) {
      if (userSnap) {
        window.firebase.firestore().collection('users').doc(userSnap.uid).get()
          .then(function(snap) {
            let user = snap.data();
            user.id = snap.id;
            self.props.saveUserData(user);
            self.props.history.push("/prenotazione");
          })
      } else {
        self.props.history.push("/");
      }
    });
  }
  render() {
    let cssClass = '';
    let title = 'Mbility | Transport for special people';
    window.document.title = title;
    return (
      <div className={"wrapper " + cssClass}>
          <Navbar />
          {/*<Sidebar />*/}
          <Switch>
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/" component={Login}/>
              <Route exact path="/forgot-password" component={Forgot}/>
              <Route exact path="/dashboard" component={Dashboard}/>
              <Route exact path="/prenotazione/thankyou" component={Thankyou}/>
              <Route exact path="/prenotazione/riepilogo" component={Riepilogo}/>
              <Route exact path="/prenotazione" component={Prenotazione}/>
              <Route exact path="/storico" component={Storico}/>
              <Route exact path="/listino" component={Listino}/>
              <Route exact path="/utenti" component={Utenti}/>
              <Route exact path="/registrazione" component={Registrazione}/>
              <Route exact path="/assenze" component={Assenze}/>
              <Route path="/users/signInWithCustomToken" component={Impersona} />
            </Suspense>
          </Switch>
          <div id="versione">1.5.0</div>
      </div>
    );
  }
}

export default withRouter(connect(null, mapDispatchToProps)(App));
